import { useEffect, useState } from "react";
import axiosParent from "utilities/axiosInstance";

export default function AppVersion(props) {

  const [backendVersion, setBackendVersion] = useState(null);
  const [backendReleaseDate, setBackendReleaseDate] = useState(null);

  const [versionString, setVersionString] = useState("v" + process.env.NEXT_PUBLIC_VERSION_CODE + "." + process.env.NEXT_PUBLIC_ENVIRONMENT + " " + process.env.NEXT_PUBLIC_RELEASE_DATE)

  const baseUrl = process.env.NEXT_PUBLIC_BACKEND_PATH;
  let ticketNumber = process.env.NEXT_PUBLIC_TICKET_NUMBER;
  const frontendVersion = process.env.NEXT_PUBLIC_VERSION_CODE
  const frontendReleaseDate = process.env.NEXT_PUBLIC_RELEASE_DATE
  const frontendEnvironment = process.env.NEXT_PUBLIC_ENVIRONMENT
  
  useEffect(() => {
    axiosParent().get(baseUrl + "/api/get_version", {
        headers: {
          'Content-Type': 'application/json',
        }
      }).then(response => {
        setBackendVersion(response.data.version)
        setBackendReleaseDate(response.data.release_date)
    }).catch(error => {
      setBackendVersion(null);
      setBackendReleaseDate(null);
    });
  },[])

  useEffect(() => {
    if (backendVersion !== null) {
      const backend_v = backendVersion.split('.');
      const frontend_v = frontendVersion.split('.');

      const length = Math.max(backend_v.length, frontend_v.length);

      var latest_version = null;

      for (let i = 0; i < length; i++) {
        if ((+backend_v[i] || 0) < (+frontend_v[i] || 0)) {
          latest_version = "frontend";
          break;
        }
        if ((+backend_v[i] || 0) > (+frontend_v[i] || 0)) {
          latest_version = "backend";
          break;
        }
      }

      if (!ticketNumber)
        ticketNumber = "";
      
      if (latest_version != null && latest_version === "backend") {
        setVersionString(ticketNumber + " v" + backendVersion + frontendEnvironment + " " + backendReleaseDate)
      } else {
        setVersionString(ticketNumber + " v" + frontendVersion + frontendEnvironment + " " + frontendReleaseDate)
      }
    }
  }, [backendVersion, backendReleaseDate])


  return(
      <div className={props.style}>
          {versionString}
      </div>
  )
}